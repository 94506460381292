<template>
	<component v-for="(section, index) in content" :key="index" :is="section.name" :data="section"></component>
</template>

<script>
	export default {
		data() {
			return {
				content: null,
			}
		},

		created() {
			let page = require( '../assets/pages/' + this.$route.meta.content );
			this.content = page.content;
		},

		mounted() {
			this.$functions.sectionPadding();
		}
	}
</script>